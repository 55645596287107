<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <base-header
      :title="$t('app.juegos_informales.name')"
      link="Dashboard"
    />
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <base-material-card color="primary">
          <template v-slot:heading>
            <v-row>
              <v-col class="flex-grow-1 flex-grow-0">
                <div class="display-2 font-weight-light">
                  {{ $t('app.juegos_informales.title') }}
                </div>

                <div class="subtitle-1 font-weight-light">
                  {{ $t('app.juegos_informales.subtitle') }}
                </div>
              </v-col>
              <v-col class="d-flex align-center flex-grow-0 flex-shrink-0">
                <xlsx-workbook v-if="excel && excel.length > 0 && excel[0].data.length > 0">
                  <xlsx-sheet
                    v-for="sheet in excel"
                    :key="sheet.name"
                    :collection="sheet.data"
                    :sheet-name="sheet.name"
                  />
                  <xlsx-download
                    :filename="excelName"
                  >
                    <v-btn
                      text
                      :disabled="excel.length == 0"
                    >
                      <v-icon>
                        mdi-file-excel-box
                      </v-icon>
                      &nbsp;
                      Exportar a Excel
                    </v-btn>
                  </xlsx-download>
                </xlsx-workbook>
                <v-spacer />
                <v-btn
                  dark
                  @click="openItemAdd"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-plus-circle
                  </v-icon>

                  {{ $t('app.buttons.add') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="10"
          >
            <template v-slot:item.color="{ item }">
              <v-chip :style="`background-color: ${rgbaColor(item.color)}`" :dark="isDarkColor(rgbaColor(item.color))" :light="isLightColor(rgbaColor(item.color))">
                Color
              </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-col class="d-flex justify-end">
                <v-btn
                  icon
                  @click="openItemDetail(item)"
                >
                  <v-icon>
                    mdi-eye
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemEdit(item)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemDelete(item)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-col>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>

    <juegos-informales-add />
    <juegos-informales-edit />
    <juegos-informales-detail />
    <juegos-informales-delete />
  </v-container>
</template>

<script>
  import { XlsxDownload, XlsxSheet, XlsxWorkbook } from 'vue-xlsx'
  export default {
    name: 'JuegosInformales',
    components: {
      JuegosInformalesAdd: () => import('./JuegosInformalesAdd'),
      JuegosInformalesEdit: () => import('./JuegosInformalesEdit'),
      JuegosInformalesDetail: () => import('./JuegosInformalesDetail'),
      JuegosInformalesDelete: () => import('./JuegosInformalesDelete'),

      XlsxWorkbook,
      XlsxSheet,
      XlsxDownload,
    },
    data () {
      return {
        headers: [
          {
            text: this.$t('app.headers.id'),
            value: 'id',
          },
          {
            text: this.$t('app.canchas.single'),
            value: 'cancha.nombre',
          },
          {
            text: this.$t('app.headers.color'),
            value: 'color'
          },
          {
            text: this.$t('app.headers.date'),
            value: 'fecha',
          },
          {
            text: this.$t('app.headers.time'),
            value: 'hora',
          },
          {
            text: this.$t('app.headers.description'),
            value: 'descripcion',
          },
          {
            text: this.$t('app.headers.contact'),
            value: 'contacto',
          },
          {
            text: this.$t('app.headers.phones'),
            value: 'telefonos',
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
          },
        ],
        items: [],
        excel: [],
        excelName: this.$t('app.juegos_informales.title') + ' - ' + this.$moment().format('YYYYMMDDHHmmss') + '.xlsx',
        excelHiddenColumns: [
          'id',
        ],
      }
    },
    mounted () {
      this.init()

      EventBus.$on('reload-items', () => {
        this.init()
      })
    },
    methods: {
      async init () {
        this.toggleLoader()

        this.items = await this.getJuegosInformales()

        this.excel = [
          {
            name: this.$t('app.general.report'),
            data: this.prepareForExcel(this.items, this.headers, this.excelHiddenColumns),
          },
        ]

        this.toggleLoader()
      },
      openItemAdd () {
        EventBus.$emit('juegos-informales-add')
      },
      openItemEdit (item) {
        EventBus.$emit('juegos-informales-edit', item)
      },
      openItemDetail (item) {
        EventBus.$emit('juegos-informales-detail', item)
      },
      openItemDelete (item) {
        EventBus.$emit('juegos-informales-delete', item)
      },
    },
  }
</script>

<style scoped>

</style>
